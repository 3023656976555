import React from 'react'
import {useRouter} from 'next/router'
import {useTranslations} from 'next-intl'
import fp from 'lodash/fp'
import Fuse from 'fuse.js'
import {useDebounce} from 'use-debounce'
import {css, useTheme} from '@emotion/react'
import {AnimatePresence, motion} from 'framer-motion'
import Scrollbars from 'react-custom-scrollbars-2'
import {Accordion, AccordionDetails, AccordionSummary, Divider, IconButton, Typography} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {FaChevronLeft, FaChevronRight} from 'react-icons/fa'

import {ToolCategories, ToolGroups} from '../../../config/categories.config'
import ToolList from '../../modules/ToolList'
import Title from '../../modules/Title'
import useToggle from '../../../hooks/useToggle'
import useAllToolInfos from '../../../hooks/useAllToolInfos'
import SearchField from '../../elements/SearchField'
import Paper from '../../elements/Paper'

const MaxToolCount = 5

function Navbar(props) {
  const [expanded, expandedToggle] = useToggle(true)

  const theme = useTheme()
  const styles = {
    root: css`
      height: 100%;
      display: flex;
      flex-direction: column;
      background: ${theme.palette.primary.light};
      width: 360px;

      position: relative;
      padding-bottom: 56px;
    `,

    header: css`
      background: ${theme.palette.primary.dark};
      padding: ${expanded ? '8px 16px' : '4px 4px'};
    `,

    searchField: css`
      margin: 8px 0;
    `,

    title: css`
      margin: 0 auto;
    `,

    content: css`
      padding: 16px;
    `,

    footer: css`
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;

      padding: 8px;
      text-align: right;

      display: flex;
      justify-content: right;
      align-items: center;
    `,
  }

  const t = useTranslations()

  const router = useRouter()

  const [searchText, setSearchText] = React.useState('')
  const onSearchTextChange = (searchText) => {
    setSearchText(searchText)
  }

  const allToolInfos = useAllToolInfos()

  const [debouncedSearchText] = useDebounce(searchText, 20)
  const filteredOnlineTools = React.useMemo(() => {
    if (debouncedSearchText) {
      const fuse = new Fuse(allToolInfos, {
        keys: [
          {name: 'label', weight: 1},
        ],
        includeMatches: true,
      })

      return highlight(fp.flow(
        fp.take(MaxToolCount),
      )(fuse.search(debouncedSearchText)))
    } else {
      return []
    }
  }, [allToolInfos, debouncedSearchText])

  const onlineToolCategories = React.useMemo(() => (
    ToolCategories
      .map(toolCategory => ({
        key: toolCategory,
        value: toolCategory,
        label: t(`tool-category.${toolCategory}`),
        groups: ToolGroups
          .map(toolGroup => [
            toolGroup,
            allToolInfos
              .filter(tool => tool.category === toolCategory)
              .filter(tool => tool.group === toolGroup)
          ])
          .filter(([_, toolInfos]) => toolInfos.length > 0)
          .map(([toolGroup, toolInfos]) => ({
            key: toolGroup,
            value: toolGroup,
            label: t(`tool-group.${toolGroup}`),
            tools: toolInfos.map(onlineTool => ({
              ...onlineTool,
              key: onlineTool.key,
              label: t(`${onlineTool.key}.title`),
              active: onlineTool.url === router.pathname,
            })),
          }))
      }))
      .filter(toolCategory => toolCategory.groups.length > 0)
  ), [t, allToolInfos, router.pathname])

  return (
    <motion.nav
      id="nav"
      css={styles.root}
      initial={false}
      animate={{width: expanded ? 360 : 56}}
      {...props}
    >
      <motion.div
        css={styles.header}
        initial={false}
        animate={{padding: expanded ? '8px 16px' : '4px 4px'}}
      >
        <Title
          css={styles.title}
          title={t('site-name')}
          expanded={expanded}
        />
        {expanded ? (
          <div css={styles.searchField}>
            <SearchField
              id="tool-search"
              placeholder={t('tool-layout.search-placeholder')}
              query={searchText}
              onQueryChange={onSearchTextChange}
            />
          </div>
        ) : null}
      </motion.div>

      <AnimatePresence>
        {expanded ? (
          <Scrollbars universal autoHide>
            <motion.div
              animate={{opacity: 1}}
              exit={{opacity: 0}}
              css={styles.content}
            >
              {searchText.length > 0 ? (
                <SearchPanel
                  css={styles.section}
                  title={t('search-results')}
                  tools={filteredOnlineTools}
                />
              ) : null}

              {searchText.length === 0 ? (
                <div css={css`
                  display: flex;
                  flex-direction: column;
                `}>
                  {onlineToolCategories.map(onlineToolCategory => (
                    <CategoryPanel key={onlineToolCategory.key} onlineToolCategory={onlineToolCategory}/>
                  ))}
                </div>
              ) : null}
            </motion.div>
          </Scrollbars>
        ) : null}
      </AnimatePresence>

      <motion.div
        css={styles.footer}
        animate={{
          height: expanded ? 'initial' : '100%',
        }}
      >
        <IconButton onClick={expandedToggle}>
          {expanded ? <FaChevronLeft/> : <FaChevronRight/>}
        </IconButton>
      </motion.div>
    </motion.nav>
  )
}


function SearchPanel({tools, ...props}) {
  const theme = useTheme()
  const styles = {
    root: css`
      padding: 16px;
      background: ${theme.palette.surface.main};
    `,

    header: css`
      font-size: 1.2rem;
      font-weight: 600;
      margin-bottom: 8px;
    `,
  }

  const t = useTranslations()

  return (
    <Paper css={styles.root} {...props}>
      <div css={styles.header}>{t('tool-layout.search-results')}</div>
      <Divider/>
      <ToolList tools={tools}/>
    </Paper>
  )
}


function CategoryPanel({onlineToolCategory, ...props}) {
  const theme = useTheme()
  const styles = {
    root: css`
    `,

    header: css`
      font-size: 1.2rem;
      font-weight: 600;
    `,

    group: css`
      padding: 16px;
      color: ${theme.palette.onSurface.dark};

      & :not(:first-of-type) {
        margin-top: 16px;
      }
    `,

    groupTitle: css`
      font-size: 1.2rem;
      font-weight: 600;
    `,
  }

  const defaultExpanded = React.useMemo(() => {
    return onlineToolCategory.groups.filter(group => group.tools.filter(tool => tool.active).length > 0).length > 0
  }, [onlineToolCategory])

  return (
    <Accordion css={styles.root} defaultExpanded={defaultExpanded} {...props}>
      <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
        <div css={styles.header}>{onlineToolCategory.label}</div>
      </AccordionSummary>
      <AccordionDetails css={styles.detail}>
        {
          onlineToolCategory.groups.map(group => (
            <Paper key={group.key} css={styles.group}>
              <Typography css={styles.groupTitle}>{group.label}</Typography>
              <ToolList tools={group.tools}/>
            </Paper>
          ))
        }
      </AccordionDetails>
    </Accordion>
  )
}

function highlight(fuseSearchResult) {
  const generateHighlightedText = (inputText, regions = []) => {
    let content = ''
    let nextUnhighlightedRegionStartingIndex = 0

    regions.forEach(region => {
      const lastRegionNextIndex = region[1] + 1

      content += [
        inputText.substring(nextUnhighlightedRegionStartingIndex, region[0]),
        `<b>`,
        inputText.substring(region[0], lastRegionNextIndex),
        '</b>',
      ].join('')

      nextUnhighlightedRegionStartingIndex = lastRegionNextIndex
    })

    content += inputText.substring(nextUnhighlightedRegionStartingIndex)

    return content
  }

  return fuseSearchResult
    .filter(({matches}) => matches && matches.length)
    .map(({item, matches}) => {
      return fp.flow(
        ...matches.map(match => fp.set(match.key,  generateHighlightedText(match.value, match.indices)))
      )(item)
    })
}


export default React.memo(Navbar)
