export const DocumentInfos = [
  {
    key: 'about',
    category: 'site',
    group: 'site-info',
    url: '/about',
    isPrivate: false,
    docKeys: [
      'about',
    ],
  },
  {
    key: 'privacy',
    category: 'site',
    group: 'site-info',
    url: '/privacy',
    isPrivate: false,
    docKeys: [
      'privacy',
    ],
  },
]

