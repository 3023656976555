import React from 'react'
import {AppCacheProvider} from '@mui/material-nextjs/v13-pagesRouter'
import {CssBaseline} from '@mui/material'

import AppProvider from '../contexts/AppProvider'
import BaseLayout from '../components/layouts/BaseLayout'

// 给 Map 使用
import 'leaflet/dist/leaflet.css'


function App(props) {
  const {Component, pageProps} = props

  return (
    <AppCacheProvider {...props}>
      <AppProvider pageProps={pageProps}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline/>

        <BaseLayout>
          <Component {...pageProps} />
        </BaseLayout>
      </AppProvider>
    </AppCacheProvider>
  )
}


export default App
