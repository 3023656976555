import React from 'react'
import {css} from '@emotion/react'
import {Button as MuiButton} from '@mui/material'


function Button({children, className, disabled, secondary, onClick, ...props}) {
  const styles = {
    root: css`
      text-transform: initial;
    `
  }

  return (
    <MuiButton
      css={styles.root}
      className={className}
      variant={secondary ? 'outlined' : 'contained'}
      disabled={disabled}
      onClick={onClick}
      {...props}
    >
      {children}
    </MuiButton>
  )
}

export default React.memo(Button)
