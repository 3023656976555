import React from 'react'
import {createReactFeature} from '@paji-sdk/web'
import Alert from '../../components/elements/Alert'


export const Severity = Object.freeze({
  Error: 'error',
  Success: 'success',
})


const feature = createReactFeature('Notifications')

export function NotificationsProvider({children}) {
  const [severity, setSeverity] = React.useState(Severity.Error)
  const [messageOpen, setMessageOpen] = React.useState(false)
  const [message, setMessage] = React.useState('')

  const showErrorMessage = (message) => {
    setSeverity(Severity.Error)
    setMessage(message)
    setMessageOpen(true)
  }

  const showSuccessMessage = (message) => {
    setSeverity(Severity.Success)
    setMessage(message)
    setMessageOpen(true)
  }

  const hideMessage = () => {
    setMessageOpen(false)
  }

  const notifications = {
    showSuccessMessage,
    showErrorMessage,
  }

  return (
    <>
      <feature.Provider value={notifications}>
        {children}
      </feature.Provider>

      <Alert
        open={messageOpen}
        severity={severity}
        message={message}
        onClose={hideMessage}
      />
    </>
  )
}


export const useNotifications = feature.useFeature
