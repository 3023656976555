import {useRouter} from 'next/router'
import {i18n} from '../next-i18next.config'

/**
 * 取得網頁 Canonical URL
 * @returns {object} canonicalURL - 網頁的 Canonical URL
 */
export default function useCanonicalUrlMap(hostUrl) {
  const {locale: currentLocale, defaultLocale, asPath} = useRouter()

  const locales = i18n.locales

  return {
    current: generateURL(hostUrl, asPath, currentLocale, defaultLocale),
    default: generateURL(hostUrl, asPath, defaultLocale, defaultLocale),
    alternatives: locales.reduce((alternatives, locale) => ({
      ...alternatives,
      [locale]: generateURL(hostUrl, asPath, locale, defaultLocale),
    }), {}),
  }
}


function generateURL(hostUrl, asPath, locale, defaultLocale) {
  const components = [hostUrl]

  // 非預設 locale 需要加 locale
  if (locale !== defaultLocale) {
    components.push(locale)
  }

  const pathEnd = Math.min(
    asPath.indexOf('?') > 0 ? asPath.indexOf('?') : asPath.length,
    asPath.indexOf('#') > 0 ? asPath.indexOf('#') : asPath.length
  )
  let pathname = asPath.substring(1, pathEnd)

  if (pathname.startsWith('m/')) {
    pathname = pathname.substring(2)
  } else if (pathname === 'm') {
    pathname = ''
  }

  if (pathname.length > 0) {
    components.push(pathname)
  }

  return components.join('/')
}