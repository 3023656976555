export const TextEncoderInfos = [
  {
    key: 'base64-encoder',
    category: 'encode-and-decode',
    group: 'base64',
    url: '/base64-encoder',
    isPrivate: false,
  },
  {
    key: 'base64-decoder',
    category: 'encode-and-decode',
    group: 'base64',
    url: '/base64-decoder',
    isPrivate: false,
  },
  {
    key: 'base32-encoder',
    category: 'encode-and-decode',
    group: 'base32',
    url: '/base32-encoder',
    isPrivate: false,
  },
  {
    key: 'base32-decoder',
    category: 'encode-and-decode',
    group: 'base32',
    url: '/base32-decoder',
    isPrivate: false,
  },
  {
    key: 'url-encoder',
    category: 'encode-and-decode',
    group: 'url',
    url: '/url-encoder',
    isPrivate: false,
  },
  {
    key: 'url-decoder',
    category: 'encode-and-decode',
    group: 'url',
    url: '/url-decoder',
    isPrivate: false,
  },
]
