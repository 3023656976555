import React from 'react'
import Head from 'next/head'
import Script from 'next/script'
import {useTranslations} from 'next-intl'
import {useRouter} from 'next/router'

import {useAppCtx} from '../../../contexts/appCtx'
import Manifest from '../../../public/manifest.json'
import {GoogleTrackingCode, HostUrl} from '../../../config/vars.config'
import useCanonicalUrlMap from '../../../utils/useCanonicalUrlMap'
import * as gtag from '../../../utils/gtag'
import BaseDesktopLayout from './BaseDesktopLayout'
import BaseMobileLayout from './BaseMobileLayout'


export default function BaseLayout(props) {
  const appCtx = useAppCtx()

  const canonicalUrlMap = useCanonicalUrlMap(HostUrl)
  const t = useTranslations()

  const router = useRouter()

  React.useEffect(() => {
    const handleRouteChange = (url) => {
      gtag.pageview(url)
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>

        <link rel="canonical" href={canonicalUrlMap.current}/>
        <link rel="alternate" href={canonicalUrlMap.default} hrefLang="x-default"/>
        {
          Object.entries(canonicalUrlMap.alternatives).map(
            ([locale, url]) => <link key={locale} rel="alternate" href={url} hrefLang={locale}/>
          )
        }

        <meta name="application-name" content={t('site-name')}/>
        <meta name="apple-mobile-web-app-capable" content="yes"/>
        <meta name="apple-mobile-web-app-status-bar-style" content="default"/>
        <meta name="apple-mobile-web-app-title" content={Manifest.short_name}/>
        <meta name="theme-color" content={Manifest.theme_color}/>

        <meta name="twitter:card" content="summary"/>
        <meta name="twitter:url" content={canonicalUrlMap.current}/>
        <meta name="twitter:image" content={`${HostUrl}/logo.png`}/>
        <meta name="twitter:creator" content="@marco79423"/>

        <meta property="og:type" content="website"/>
        <meta property="og:site_name" content={t('site-name')}/>
        <meta property="og:url" content={canonicalUrlMap.current}/>
        <meta property="og:image" content={`${HostUrl}/logo.png`}/>
      </Head>

      {/* Global Site Tag (gtag.js) - Google Analytics */}
      <Script
        id="gtag"
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${GoogleTrackingCode}`}
      />
      <Script
        id="gtag-disable-script"
        dangerouslySetInnerHTML={{
          __html: `  
            if (window.location.hostname === 'localhost') {
                window['ga-disable-${GoogleTrackingCode}'] = true;
            }
          `,
        }}
      />
      <Script
        id="gtag-script"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${GoogleTrackingCode}', {
              page_path: window.location.pathname,
            });
            
            <!-- Event snippet for 網頁瀏覽 conversion page -->
            gtag('event', 'conversion', {'send_to': 'AW-1005370006/XtzZCLnv3YcZEJb1st8D'});
          `,
        }}
      />

      <Script
        id="clarity-script"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
             (function(c,l,a,r,i,t,y){
               c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
               t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
               y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
             })(window, document, "clarity", "script", "mys5otx1y4");
          `,
        }}
      />

      {/* Desktop Mode */}
      {!appCtx.isMobile && (
        <BaseDesktopLayout {...props}/>
      )}
      {/* Mobile Mode */}
      {appCtx.isMobile && (
        <BaseMobileLayout {...props}/>
      )}

    </>
  )
}
