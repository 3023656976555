import React from 'react'
import {useTranslations} from 'next-intl'

import {useDeveloperMode} from '../contexts/developerMode'
import {AllToolInfos} from '../core/tools/infos.config'


export default function useAllToolInfos() {
  const t = useTranslations()
  const developMode = useDeveloperMode()

  return React.useMemo(() => AllToolInfos
    .filter(info => developMode || !info.isPrivate)
    .map(info => ({
      ...info,
      label: t(`${info.key}.title`)
    })), [t, developMode])
}
