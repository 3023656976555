export const CodeComparerInfos = [
  {
    key: `json-compare`,
    category: 'data-format-minify-merge-compare',
    group: 'json',
    url: `/json-compare`,
    isPrivate: false,
  },
  {
    key: `yaml-compare`,
    category: 'data-format-minify-merge-compare',
    group: 'yaml',
    url: `/yaml-compare`,
    isPrivate: false,
  },
  {
    key: `toml-compare`,
    category: 'data-format-minify-merge-compare',
    group: 'toml',
    url: `/toml-compare`,
    isPrivate: false,
  },
  {
    key: `text-compare`,
    category: 'data-format-minify-merge-compare',
    group: 'text',
    url: `/text-compare`,
    isPrivate: false,
  },
]