export const CodeConverterInfos = [
  ['json', 'yaml'],
  ['json', 'toml'],
  ['json', 'js-object'],

  ['yaml', 'json'],
  ['yaml', 'toml'],
  ['yaml', 'js-object'],

  ['toml', 'json'],
  ['toml', 'yaml'],
  ['toml', 'js-object'],

  ['js-object', 'json'],
  ['js-object', 'yaml'],
  ['js-object', 'toml'],

  ['py-dict', 'json'],
  ['py-dict', 'yaml'],
  ['py-dict', 'toml'],
].map(([inputKey, outputKey]) => ({
  key: `${inputKey}-to-${outputKey}-converter`,
  category: 'data-convert',
  group: inputKey,
  url: `/${inputKey}-to-${outputKey}-converter`,
  isPrivate: false,
}))
