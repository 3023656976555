export const TextConverterInfos = [
  {
    key: 'traditional-simplified-converter',
    category: 'text-edit',
    group: 'language',
    url: '/traditional-simplified-converter',
    isPrivate: false,
  },
  {
    key: 'simplified-traditional-converter',
    category: 'text-edit',
    group: 'language',
    url: '/simplified-traditional-converter',
    isPrivate: false,
  },
  {
    key: 'tabs-to-spaces-converter',
    category: 'text-edit',
    group: 'tabs-and-spaces',
    url: '/tabs-to-spaces-converter',
    isPrivate: false,
  },
]
