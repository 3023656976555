import React from 'react'
import {ProviderCompose} from '@paji-sdk/web'
import {QueryClient, QueryClientProvider} from 'react-query'

import {NotificationsProvider} from '../hooks/notifications'
import {ThemeProvider} from '../hooks/theme'
import {DarkModeProvider} from '../hooks/darkMode'
import {DeveloperModeProvider} from './developerMode'
import {AppCtxProvider} from './appCtx'
import {ToolInfoProvider} from './toolInfo'
import {NextIntlClientProvider} from 'next-intl'
import {useRouter} from 'next/router'

const queryClient = new QueryClient()

/**
 * 所有 App 所需的 Provider
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
function AppProvider({pageProps, children}) {
  const router = useRouter()

  return (
    <ProviderCompose.Composer providers={[
      ProviderCompose.provider(NextIntlClientProvider, {
        locale: router.locale,
        messages: pageProps.messages,
        timeZone: 'UTC',
      }),

      // React Query 的 Provider
      ProviderCompose.provider(QueryClientProvider, {client: queryClient}),

      // Context
      ProviderCompose.provider(AppCtxProvider, {ctx: pageProps}),
      ProviderCompose.provider(ToolInfoProvider, {ctx: pageProps}),
      ProviderCompose.provider(DeveloperModeProvider, {ctx: pageProps}),
      ProviderCompose.provider(DarkModeProvider),
      ProviderCompose.provider(ThemeProvider),
      ProviderCompose.provider(NotificationsProvider),
    ]}>
      {children}
    </ProviderCompose.Composer>
  )
}

export default AppProvider
