export const HashGeneratorInfos = [
  {
    key: 'md5-generator',
    category: 'hash',
    group: 'md5',
    url: '/md5-generator',
    isPrivate: false,
  },

  ...[
    'sha1',
    'sha224',
    'sha256',
    'sha384',
    'sha512',
  ].map(scheme => ({
    key: `${scheme}-generator`,
    category: 'hash',
    group: 'sha-family',
    url: `/${scheme}-generator`,
    isPrivate: false,
  })),

]
