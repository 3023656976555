export const ImageConverterInfos = [
  {
    key: 'png-converter',
    category: 'image',
    group: 'image-convert',
    url: '/png-converter',
    isPrivate: false,
  },
  {
    key: 'jpg-converter',
    category: 'image',
    group: 'image-convert',
    url: '/jpg-converter',
    isPrivate: false,
  },
  {
    key: 'bmp-converter',
    category: 'image',
    group: 'image-convert',
    url: '/bmp-converter',
    isPrivate: false,
  },
  {
    key: 'tiff-converter',
    category: 'image',
    group: 'image-convert',
    url: '/tiff-converter',
    isPrivate: false,
  },
]

