import {TextEncoderInfos} from './TextEncoders/infos.config'
import {CodeConverterInfos} from './CodeConverters/infos.config'
import {CodeComparerInfos} from './CodeComparers/infos.config'
import {CodeFormatterInfos} from './CodeFormatters/infos.config'
import {CodeMinifierInfos} from './CodeMinifiers/infos.config'
import {CodeMergerInfos} from './CodeMergers/infos.config'
import {HashGeneratorInfos} from './HashGenerators/infos.config'
import {TextConverterInfos} from './TextConverters/infos.config'
import {ImageConverterInfos} from './ImageConverters/infos.config'
import {DocumentInfos} from './Documents/infos.config'
import {MiscellaneousToolInfos} from './Miscellaneous/infos.config'

export const AllToolInfos = [
  ...TextEncoderInfos,
  ...CodeFormatterInfos,
  ...CodeMinifierInfos,
  ...CodeConverterInfos,
  ...CodeMergerInfos,
  ...CodeComparerInfos,
  ...HashGeneratorInfos,
  ...TextConverterInfos,
  ...ImageConverterInfos,
  ...DocumentInfos,
  ...MiscellaneousToolInfos,
]

export const AllToolInfoMap = AllToolInfos
  .reduce((toolInfoMap, tool) => ({
    ...toolInfoMap,
    [tool.key]: tool,
  }), {})
