import React from 'react'
import {css, useTheme} from '@emotion/react'
import Link from 'next/link'
import Image from 'next/image'

import {useDeveloperMode} from '../../contexts/developerMode'


function Title({title, expanded = true, ...props}) {
  const theme = useTheme()
  const styles = {
    root: css`
      text-decoration: none;
      width: fit-content;

      display: flex;
      align-items: center;
    `,
    title: css`
      margin: 0;
      font-size: 2rem;
      font-weight: 800;
      color: ${theme.palette.onPrimary.main};

      @media (max-width: ${theme.breakpoints.sm}px) {
        font-size: 1.5rem;
      }
    `,

    mode: css`
      margin-left: 4px;
      color: ${theme.palette.secondary.dark};
      font-size: 1rem;
    `
  }

  const developMode = useDeveloperMode()

  return (
    <Link css={styles.root} href="/" {...props}>
      {expanded ? (
        <div css={styles.title}>
          {title}
          {developMode ? (
            <span css={styles.mode}>[dev]</span>
          ) : null}
        </div>
      ) : (
        <Image
          width={48}
          height={48}
          src="/favicon.svg"
          alt={'logo'}

          initial={false}
          animate={{opacity: 1}}
          transition={{type: 'linear'}}
          exit={{opacity: 0}}
        />
      )}
    </Link>
  )
}

export default React.memo(Title)
